import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Form, Button, InputGroup } from 'react-bootstrap';

import { SubmitForm, Submit } from './index';
import { getLabel } from './helpers';

const PREVIEW = 'EditableGroup.Preview';
const EDITING = 'EditableGroup.Editing';
const STATICTEXT = 'EditableGroup.StaticText';

export const EditableGroup = props => {
  const [editing, setEditing] = useState(false);

  return (
    <ControlledEditableGroup
      editing={editing}
      setEditing={setEditing}
      {...props}
    />
  );
};

EditableGroup.propTypes = {
  /** Sets the editing mode */
  editing: PropTypes.bool,
  /** Sets the setEditing function */
  setEditing: PropTypes.func,
  /** Sets the data mutation function */
  mutation: PropTypes.shape({
    key: PropTypes.string.isRequired,
    variables: PropTypes.object,
    diff: PropTypes.object,
    mutate: PropTypes.func.isRequired,
    onMutate: PropTypes.func.isRequired,
    skip: PropTypes.func,
    onSkip: PropTypes.func,
  }).isRequired,
  /** Fills in the <Form.Label> for the form-group */
  label: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  /** Sets the text for the submit labels */
  submitText: PropTypes.shape({
    saving: PropTypes.string,
    static: PropTypes.string,
  }).isRequired,
  /** For display purposes only, Adds a red * next to the label */
  required: PropTypes.bool.isRequired,
  /** Sets the saving state of the component */
  saving: PropTypes.bool.isRequired,
  /** Sets the disabled state of the component */
  disabled: PropTypes.bool.isRequired,
  /** Adds the horizontal ruler at the bottom */
  labelBorder: PropTypes.bool.isRequired,
  /** Action to take on submission */
  onSubmit: PropTypes.func,
};

EditableGroup.displayName = 'EditableGroup';

EditableGroup.defaultProps = {
  required: false,
  saving: false,
  disabled: false,
  labelBorder: true,
  submitText: {},
};

export const ControlledEditableGroup = ({
  editing,
  setEditing,
  mutation,
  label,
  submitText,
  required,
  saving,
  disabled,
  onSubmit,
  labelBorder,
  children,
}) => {
  const { t } = useTranslation();

  const groupClasses = classNames({
    'form-group': true,
    'osg-editable-group': true,
    'osg-editable-group--label-border': labelBorder,
    'osg-editable-group--editing': editing,
  });

  if (!editing)
    return (
      <div className={groupClasses}>
        <label className="form-label">{getLabel({ label, required })}</label>
        <button
          className="osg-editable-group__preview"
          onClick={() => !disabled && !saving && setEditing(true)}
        >
          <div className="osg-editable-group__content">
            {children.filter(child => PREVIEW === child?.type?.displayName)}
          </div>
          <Button
            className="osg-editable-group__edit-link"
            disabled={saving || disabled}
            as="div"
            variant="link"
          >
            {t('Edit')}
          </Button>
        </button>
      </div>
    );

  const body = children.filter(child => EDITING === child?.type?.displayName);

  return (
    <SubmitForm
      className={groupClasses}
      mutation={{
        ...mutation,
        onMutate: (...all) => {
          setEditing(false);
          mutation.onMutate(...all);
        },
        onSkip: (...all) => {
          setEditing(false);
          if (mutation.onSkip) mutation.onSkip(...all);
        },
      }}
      escape={({ key }) => 'Escape' === key && setEditing(false)}
    >
      {body.length > 1 ? body : body[0]}
      <div className="d-flex justify-content-end">
        {!saving && (
          <Button
            type="reset"
            variant="link"
            size="sm"
            disabled={disabled}
            onClick={() => setEditing(false)}
          >
            {t('Cancel')}
          </Button>
        )}
        <Submit
          saving={saving}
          disabled={disabled}
          savingText={submitText.saving || t('Updating')}
          onClick={onSubmit}
          size="sm"
        >
          {submitText.static || t('Update')}
        </Submit>
      </div>
    </SubmitForm>
  );
};

ControlledEditableGroup.propTypes = {
  /** Sets the editing mode */
  editing: PropTypes.bool.isRequired,
  /** Sets the setEditing function */
  setEditing: PropTypes.func.isRequired,
  /** Sets the data mutation function */
  mutation: PropTypes.shape({
    key: PropTypes.string.isRequired,
    variables: PropTypes.object,
    diff: PropTypes.object,
    mutate: PropTypes.func.isRequired,
    onMutate: PropTypes.func.isRequired,
  }).isRequired,
  /** Fills in the <Form.Label> for the form-group */
  label: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  /** Sets the text for the submit labels */
  submitText: PropTypes.shape({
    saving: PropTypes.string,
    static: PropTypes.string,
  }).isRequired,
  /** For display purposes only, Adds a red * next to the label */
  required: PropTypes.bool.isRequired,
  /** Sets the saving state of the component */
  saving: PropTypes.bool.isRequired,
  /** Sets the disabled state of the component */
  disabled: PropTypes.bool.isRequired,
  /** Adds the horizontal ruler at the bottom */
  labelBorder: PropTypes.bool.isRequired,
  /** Action to take on submission */
  onSubmit: PropTypes.func,
};

EditableGroup.Preview = ({
  readOnly,
  muted,
  plaintext,
  prepend,
  append,
  children,
}) => (
  <React.Fragment>
    {readOnly ? (
      prepend || append ? (
        <InputGroup>
          {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
          <EditableGroup.Preview.Input
            muted={muted}
            plaintext={plaintext}
            value={children}
          />
          {append && <InputGroup.Text>{append}</InputGroup.Text>}
        </InputGroup>
      ) : (
        <EditableGroup.Preview.Input
          muted={muted}
          plaintext={plaintext}
          value={children}
        />
      )
    ) : (
      children
    )}
  </React.Fragment>
);

EditableGroup.Preview.Input = ({ muted, plaintext, value }) =>
  plaintext ? (
    <span className={muted ? 'text-muted' : undefined}>{value}</span>
  ) : (
    <Form.Control
      tabIndex="-1"
      type="text"
      className={muted ? 'text-muted' : undefined}
      value={value}
      readOnly
    />
  );

EditableGroup.Preview.propTypes = {
  /** Add Bootstrap Form.Control readOnly component around text */
  readOnly: PropTypes.bool.isRequired,
  /** Add Bootstrap Form.Control muted className on readOnly component */
  muted: PropTypes.bool.isRequired,
  /** Add Bootstrap Form.Control plaintext prop on readOnly component */
  plaintext: PropTypes.bool.isRequired,
  /** Add Bootstrap InputGroup.Text plaintext prop on readOnly component */
  prepend: PropTypes.node,
  /** Add Bootstrap InputGroup.Text plaintext prop on readOnly component */
  append: PropTypes.node,
};

EditableGroup.Preview.defaultProps = {
  readOnly: false,
  muted: false,
  plaintext: false,
};

EditableGroup.Editing = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);

EditableGroup.StaticText = ({ label, required, children, labelBorder }) => (
  <div
    className={classNames({
      'form-group': true,
      'osg-editable-group': true,
      'osg-editable-group--label-border': labelBorder,
    })}
  >
    <label className="form-label">{getLabel({ label, required })}</label>
    <div className="osg-editable-group__preview">
      <div className="osg-editable-group__content">{children}</div>
    </div>
  </div>
);

EditableGroup.StaticText.propTypes = {
  /** Fills in the <Form.Label> for the form-group */
  label: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  /** For display purposes only, Adds a red * next to the label */
  required: PropTypes.bool.isRequired,
  /** Adds a bottom border on the label and input */
  labelBorder: PropTypes.bool,
};

EditableGroup.StaticText.defaultProps = {
  required: false,
  labelBorder: false,
};

EditableGroup.displayName = 'EditableGroup';
EditableGroup.Preview.displayName = PREVIEW;
EditableGroup.Editing.displayName = EDITING;
EditableGroup.StaticText.displayName = STATICTEXT;
