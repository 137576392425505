import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { IconSaving } from '../../components/Icon';

export const Submit = ({
  form,
  children,
  savingText,
  saving,
  disabled,
  onClick,
  ...props
}) => (
  <Button
    {...props}
    form={form}
    type="submit"
    disabled={saving || disabled}
    onClick={onClick}
  >
    {saving ? (
      <React.Fragment>
        {savingText || children} <IconSaving right />
      </React.Fragment>
    ) : (
      children
    )}
  </Button>
);

Submit.propTypes = {
  form: PropTypes.string,
  saving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  savingText: PropTypes.node,
  onClick: PropTypes.func,
};

Submit.defaultProps = {
  saving: false,
  disabled: false,
  variant: 'success',
};
