import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useImpersonationContext } from '../../helpers/ImpersonationProvider';
import { Icon } from '../Icon';

export const ImpersonateAlert = ({ className, fullName }) => {
  const { t } = useTranslation();
  const { unimpersonate } = useImpersonationContext();

  const classes = classNames({
    'osg-impersonate-alert': true,
    [className]: className,
  });

  return (
    <Alert variant="dark" className={classes}>
      <div className="osg-impersonate-alert__message">
        <Icon
          icon="user-secret"
          variant="solid"
          size="fa-lg"
          className="me-2"
        />
        {t('You Are Currently Impersonating')}
      </div>
      <kbd className="osg-impersonate-alert__user">{fullName}</kbd>
      <Button
        onClick={() => unimpersonate()}
        className="osg-impersonate-alert__button"
        size="xs"
        variant="outline-dark"
      >
        {t('Stop Impersonating')}
      </Button>
    </Alert>
  );
};

ImpersonateAlert.propTypes = {
  className: PropTypes.string,
};

ImpersonateAlert.defaultProps = {
  className: null,
};
