import React, { useContext } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import {
  SignInMutation,
  SignUpMutation,
  VerifyRegisterationCode,
  RecoverLoginCredentialsMutation,
  RegisterMutation,
  UpdatePasswordMutation,
} from '../../graphql/Login.gql';

import { SubmitForm, Input, Submit } from '../../components/SubmitForm';

import { TranslateSwitch } from '../../components/TranslateSwitch';
import { LoginContext } from '../../actions/userRoles';

// const uuidRegex =
//   '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

function LoginContainer({ location, setCurrentLogin }) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useMutation(SignInMutation);
  const onMutate = ({ login }) => login && setCurrentLogin(login);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('Sign In')} | OSG</title>
      </Helmet>

      <div className="osg-login-form">
        <h1>{t('Account Sign In')}</h1>
        {/* <div className = "osg-login-form__create-account">
          <Trans i18nKey = "__newToOsg">
            New to OSG Account? <Link to = "/login/signup">Create an account</Link>
          </Trans>
        </div> */}
        <SubmitForm
          className="osg-login-form__log-in"
          mutation={{
            key: 'signIn',
            mutate,
            onMutate,
          }}
        >
          {location.state?.from && '/' !== location.state.from.pathname && (
            <Alert variant="danger">
              {t('You must sign in before continuing.')}
            </Alert>
          )}
          <Input
            name="username"
            label={t('Username')}
            disabled={loading}
            size="lg"
            required
            validateOnBlur={false}
            errors={{ required: t('Please enter your username.') }}
          />
          <Input
            type={Input.PASSWORD}
            name="password"
            label={t('Password')}
            disabled={loading}
            size="lg"
            required
            validateOnBlur={false}
            errors={{ required: t('Please enter your password.') }}
          />
          <div className="actions">
            <Submit
              saving={loading}
              savingText={t('Signing In')}
              variant="primary"
              size="lg"
            >
              {t('Sign In')}
            </Submit>
            {!loading && (
              <Link className="btn btn-link" to="/login/reset-password">
                {t('Forgot username or password?')}
              </Link>
            )}
          </div>
          <TranslateSwitch className="osg-translate-switch--login" />
        </SubmitForm>
      </div>
    </React.Fragment>
  );
}

function SignUpContainer({ history }) {
  const { t, i18n } = useTranslation();
  const [mutate, { loading }] = useMutation(SignUpMutation);
  const onMutate = ({ message }) => {
    toast.success(message[i18n.language]);
    history.push('/login');
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('Sign Up')}</title>
      </Helmet>
      <div className="osg-login-form">
        <h1>{t('Sign Up')}</h1>
        <div className="osg-login-form__create-account">
          {/* prettier-ignore */}
          <Trans i18nKey="__rememberUserPass">
            Remember your Username and Password? <Link to="/login">Sign in</Link>
          </Trans>
        </div>
        <SubmitForm
          className="osg-login-form__log-in"
          mutation={{
            key: 'signUp',
            variables: { locale: i18n.language },
            mutate,
            onMutate,
          }}
        >
          <Input
            name="company"
            label={t('Company')}
            disabled={loading}
            size="lg"
          />
          <Input
            type={Input.EMAIL}
            name="email"
            label={t('Email Address')}
            disabled={loading}
            size="lg"
            required
            errors={{
              required: t('Please enter your email.'),
            }}
          />
          <Input
            type="text"
            name="pass"
            tabindex={-1}
            autoComplete="off"
            className="osg-signup-pass"
            label=""
            ariaAttributes={{ 'aria-hidden': true }}
          />

          <div className="actions">
            <Submit
              saving={loading}
              savingText={t('Signing Up')}
              variant="primary"
              size="lg"
            >
              {t('Sign Up')}
            </Submit>
          </div>
          <TranslateSwitch className="osg-translate-switch--login" />
        </SubmitForm>
      </div>
    </React.Fragment>
  );
}

function RecoverLoginCredentialsContainer({ history }) {
  const { t, i18n } = useTranslation();
  const [mutate, { loading }] = useMutation(RecoverLoginCredentialsMutation);
  const onMutate = ({ message }) => {
    toast.success(message[i18n.language]);
    history.push('/login');
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('Recover Login Credentials')}</title>
      </Helmet>
      <div className="osg-login-form">
        <h1>{t('Recover Credentials')}</h1>
        <div className="osg-login-form__create-account">
          {/* prettier-ignore */}
          <Trans i18nKey="__rememberUserPass">
            Remember your Username and Password? <Link to="/login">Sign in</Link>
          </Trans>
        </div>
        {/* <div className = "osg-login-form__create-account">
          <Trans i18nKey = "__newToOsg">
            New to OSG Account? <Link to = "/login/signup">Create an account</Link>
          </Trans>
        </div> */}
        <SubmitForm
          className="osg-login-form__log-in"
          mutation={{
            key: 'recoverLoginCredentials',
            mutate,
            onMutate,
          }}
        >
          <Input
            type={Input.EMAIL}
            name="email"
            label={t('Email Address')}
            disabled={loading}
            size="lg"
            required
            validateOnBlur={false}
            errors={{
              required: t('Please enter your email.'),
            }}
          />
          <div className="actions">
            <Submit
              saving={loading}
              savingText={t('Looking up user credentials')}
              variant="primary"
              size="lg"
            >
              {t('Send')}
            </Submit>
          </div>
          <TranslateSwitch className="osg-translate-switch--login" />
        </SubmitForm>
      </div>
    </React.Fragment>
  );
}

function VerifyRegisterContainer({ code, setCurrentLogin }) {
  const { t, i18n } = useTranslation();
  const { data, loading } = useQuery(VerifyRegisterationCode, {
    variables: { code },
  });

  if (loading || !data.verify.errors)
    return (
      <React.Fragment>
        <Helmet>
          <title>{t('Complete Registration')}</title>
        </Helmet>
        <RegisterContainer
          disabled={loading}
          code={code}
          setCurrentLogin={setCurrentLogin}
        />
      </React.Fragment>
    );

  toast.error(data.verify.errors[0].message[i18n.language]);
  return <Redirect to="/login" />;
}

function RegisterContainer({ code, setCurrentLogin, disabled }) {
  const { t } = useTranslation();
  const [mutate, { loading }] = useMutation(RegisterMutation);
  const onMutate = ({ login }) => login && setCurrentLogin(login);

  return (
    <div className="osg-login-form">
      <h1>{t('Complete Registration')}</h1>
      <SubmitForm
        className="osg-login-form__register"
        mutation={{
          key: 'register',
          variables: { code },
          mutate,
          onMutate,
        }}
      >
        <Input
          name="username"
          label={t('Username')}
          disabled={disabled || loading}
          size="lg"
          required
        />
        <Input
          type={Input.PASSWORD}
          name="password"
          label={t('Password')}
          disabled={disabled || loading}
          variant={Input.PASSWORD_STRENGTH_METER}
          size="lg"
          required
        />
        <div className="actions">
          <Submit
            saving={loading}
            savingText={t('Registering')}
            variant="primary"
            size="lg"
            disabled={disabled}
          >
            {t('Register')}
          </Submit>
        </div>
        <TranslateSwitch className="osg-translate-switch--login" />
      </SubmitForm>
    </div>
  );
}

function UpdatePasswordContainer({ token, history }) {
  const { t, i18n } = useTranslation();
  const [mutate, { loading }] = useMutation(UpdatePasswordMutation);
  const onMutate = ({ message }) => {
    toast.success(message[i18n.language]);
    history.push('/login');
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('Update Password')}</title>
      </Helmet>
      <div className="osg-login-form">
        <h1>{t('Update Password')}</h1>
        <SubmitForm
          className="osg-login-form__log-in"
          mutation={{
            key: 'updatePassword',
            variables: { token },
            mutate,
            onMutate,
          }}
        >
          <Input
            type={Input.PASSWORD}
            name="password"
            label={t('Password')}
            disabled={loading}
            variant={Input.PASSWORD_STRENGTH_METER}
            size="lg"
            required
          />
          <div className="actions">
            <Submit
              saving={loading}
              savingText={t('Updating Password')}
              variant="primary"
              size="lg"
            >
              {t('Update Password')}
            </Submit>
          </div>
          <TranslateSwitch className="osg-translate-switch--login" />
        </SubmitForm>
      </div>
    </React.Fragment>
  );
}

export function Login({ location, history }) {
  const { currentLogin, setCurrentLogin } = useContext(LoginContext);

  if (currentLogin) {
    return <Redirect to={location?.state?.from || '/'} />;
  }

  return (
    <div className="osg-login">
      <Switch>
        <Route
          exact
          path="/login/reset-password/:token"
          component={({ match }) => (
            <UpdatePasswordContainer
              token={match.params.token}
              setCurrentLogin={setCurrentLogin}
              history={history}
            />
          )}
        />
        <Route exact path="/login/reset-password">
          <RecoverLoginCredentialsContainer history={history} />
        </Route>
        <Route exact path="/login/signup">
          <SignUpContainer history={history} />
        </Route>
        <Route
          exact
          path={`/login/register/:code`}
          component={({ match }) => (
            <VerifyRegisterContainer
              code={match.params.code}
              setCurrentLogin={setCurrentLogin}
            />
          )}
        />
        <Route exact path="/login">
          <LoginContainer
            location={location}
            setCurrentLogin={setCurrentLogin}
          />
        </Route>
      </Switch>
    </div>
  );
}
