import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { Icon } from '../Icon';

export const MenuItem = ({
  children,
  className,
  onClick,
  to,
  href,
  heading,
  badge,
  badgeVariant,
  icon,
  iconVariant,
  iconClasses,
  disabled,
  skeleton,
  danger,
  active,
}) => {
  const classes = classNames({
    'osg-menu-item': true,
    'osg-menu-item--heading': heading,
    'osg-menu-item--skeleton': skeleton,
    'osg-menu-item--danger': danger,
    [className]: className,
  });

  const iconClass = classNames({
    'fa-fw': true,
    [`${iconClasses}`]: iconClasses,
  });

  const linkClass = classNames({
    'nav-link': true,
    active: active,
  });

  if (skeleton) {
    return <Nav.Item className={classes}></Nav.Item>;
  }

  return (
    <Nav.Item className={classes}>
      {heading ? (
        children
      ) : !disabled ? (
        to ? (
          <NavLink
            className={linkClass}
            exact
            to={to}
            disabled={disabled}
            onClick={onClick}
          >
            {icon ? (
              <Icon icon={icon} className={iconClass} variant={iconVariant} />
            ) : null}
            <span data-badge={badge} data-badge-variant={badgeVariant}>
              {children}
            </span>
          </NavLink>
        ) : href ? (
          <Nav.Link
            className={linkClass}
            onClick={onClick}
            href={href}
            target="_blank"
            disabled={disabled}
          >
            {icon ? (
              <Icon icon={icon} className={iconClass} variant={iconVariant} />
            ) : null}
            <span data-badge={badge} data-badge-variant={badgeVariant}>
              {children}
            </span>
          </Nav.Link>
        ) : (
          <Nav.Link className={linkClass} onClick={onClick} disabled={disabled}>
            {icon ? (
              <Icon icon={icon} className={iconClass} variant={iconVariant} />
            ) : null}
            <span data-badge={badge} data-badge-variant={badgeVariant}>
              {children}
            </span>
          </Nav.Link>
        )
      ) : (
        <div className="nav-link" disabled="disabled">
          {icon ? (
            <Icon icon={icon} className={iconClass} variant={iconVariant} />
          ) : null}
          <span data-badge={badge} data-badge-variant={badgeVariant}>
            {children}
          </span>
        </div>
      )}
    </Nav.Item>
  );
};

MenuItem.propTypes = {
  children: PropTypes.node,
  /** Additional Classes applied to the Nav Item  */
  className: PropTypes.string,
  /** Set as title above other components, only children are in use if "true" */
  heading: PropTypes.bool,
  /** Uses React Bootstrap Nav.Link  */
  onClick: PropTypes.func,
  /** Overrides Nav.Link and uses React Router NavLink for navigating within app */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** For sending to an external link. */
  href: PropTypes.string,
  icon: PropTypes.string,
  iconClasses: PropTypes.string,
  iconVariant: PropTypes.oneOf(['solid', 'regular', 'light', 'duotone']),
  /** Mark a nav item with extra context. If badge is added but no contents, it will display a dot */
  badge: PropTypes.node,
  /** Color and Background for the applicable badge. Requires "badge" defined, uses Bootstrap variant values */
  badgeVariant: PropTypes.oneOf([
    'success',
    'danger',
    'warning',
    'info',
    'primary',
    'secondary',
    'light',
    'dark',
  ]),
  /** React Router will add it's own "active" class to the link, but use this to get the same style without matching Route  */
  active: PropTypes.bool,
  /** Use to show a link in the menu but disable "to" and "onClick" completely */
  disabled: PropTypes.bool,
  /** Red style for use in dangerous actions (example: Sign Out) */
  danger: PropTypes.bool,
  /** Loading view */
  skeleton: PropTypes.bool,
};

MenuItem.defaultProps = {
  className: null,
  heading: false,
  to: null,
  href: null,
  onClick: null,
  badge: null,
  badgeVariant: null,
  active: false,
  disabled: false,
  danger: false,
  skeleton: false,
};
