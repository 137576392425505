import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { NavDropdown } from 'react-bootstrap';

import { MenuItem } from '../MenuItem';

import { LoginContext } from '../../actions/userRoles';

import { SignOutMutation } from '../../graphql/Login.gql';

export const LogOut = ({ history, className, skeleton, dropdown }) => {
  const { t } = useTranslation();
  const { currentLogin, setCurrentLogin } = useContext(LoginContext);
  const client = useApolloClient();

  const [logout] = useMutation(SignOutMutation, {
    onCompleted: () => {
      history.push('/login');
      client.resetStore();
      window.localStorage.setItem('impersonationActive', JSON.stringify(false));
      setCurrentLogin(null);
    },
  });

  const classes = classNames({
    'osg-log-out': true,
    'osg-log-out--skeleton': skeleton,
    [className]: className,
  });

  if (skeleton) {
    return <MenuItem className={classes} skeleton />;
  }

  if (currentLogin === null) {
    return false;
  }

  if (dropdown) {
    return (
      <NavDropdown.Item className={classes} onClick={logout}>
        {t('Sign Out')}
      </NavDropdown.Item>
    );
  }

  return (
    <MenuItem className={classes} onClick={logout} icon="sign-out">
      {t('Sign Out')}
    </MenuItem>
  );
};

LogOut.propTypes = {
  className: PropTypes.string,
  skeleton: PropTypes.bool,
  history: PropTypes.object,
  dropdown: PropTypes.bool,
};

LogOut.defaultProps = {
  className: null,
  history: {},
  skeleton: false,
  dropdown: false,
};
