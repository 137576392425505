// Constructs and returns a new copy of the specified object renaming any specified fields to the specified value
export const FieldMapper = (item, mappings) =>
  Object.keys(item).reduce(
    (r, f) => Object.assign(r, { [mappings[f] || f]: item[f] }),
    {}
  );

// Constructs and returns a new object selecting only the specified key:value pairs from the specified object
export const FieldSelector = (item, fields) =>
  fields.reduce(
    (r, f) =>
      Object.keys(item).includes(f) ? Object.assign(r, { [f]: item[f] }) : r,
    {}
  );

export const randomStringGenerator = () =>
  Math.random().toString(26).substring(2);

export const formatDateStringMDY = date => {
  if (date) {
    const d = date.split('-');
    return `${d[1]}-${d[2]}-${d[0]}`;
  }
};
