import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, ProgressBar } from 'react-bootstrap';

import { DirectUploader } from '../../../../helpers/DirectUploader';
import { useTimeGuardedCallback } from '../../../../helpers/Hooks';

export const File = React.forwardRef(
  (
    {
      id,
      name,
      disabled,
      isInvalid,
      onStart,
      onUpdate,
      onComplete,
      onError,
      setValue,
      onKeyDown,
      onBlur,
    },
    ref
  ) => {
    const { i18n } = useTranslation();
    const [uploading, setUploading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [completed, setCompleted] = useState(false);

    const [onUpdateCallback] = useTimeGuardedCallback(
      0.5,
      ({ loaded, total }) => setPercentage(Math.round((loaded / total) * 100))
    );

    const onChange = ({ target }) => {
      const file = target.files[0];

      if (file) {
        setUploading(true);
        setCompleted(false);
        setValue(undefined, false);
        if (onStart) onStart();
        new DirectUploader(file, {
          onUpdate: props => {
            onUpdateCallback(props);
            if (onUpdate) onUpdate(props);
          },
          onComplete: ({ signed_id }) => {
            setUploading(false);
            setPercentage(0);
            setCompleted(true);
            setValue(signed_id, false);
            if (onComplete) onComplete(signed_id);
          },
          onError: error => {
            setUploading(false);
            setPercentage(0);
            setCompleted(false);
            setValue(undefined, 'upload');
            if (onError) onError(error);
          },
        }).start();
      }
    };

    return (
      <React.Fragment>
        <div className="osg-file mb-2">
          <Form.Control
            ref={ref}
            id={id}
            type="file"
            name={name}
            disabled={disabled || uploading}
            isInvalid={isInvalid}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            lang={i18n.language}
          />
        </div>
        {uploading && (
          <ProgressBar
            label={`${percentage}%`}
            animated={true}
            now={percentage}
            variant="primary"
            striped
          />
        )}
        {completed && (
          <ProgressBar
            label="100%"
            animated={false}
            now={100}
            variant="success"
            striped
          />
        )}
      </React.Fragment>
    );
  }
);

File.displayName = 'File';

File.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  onStart: PropTypes.func,
  onUpdate: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};
