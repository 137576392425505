import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { LoginContext } from '../../actions/userRoles';

const authorize = (login, props, { component: Component }) =>
  login ? (
    <Component {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  );

export function PrivateRoute({ component, ...rest }) {
  const { currentLogin } = useContext(LoginContext);

  return (
    <Route
      {...rest}
      render={props => authorize(currentLogin, props, { component })}
    />
  );
}
