import React from 'react';

import { IconRequired } from '../../components/Icon';

export const getLabel = (props, state = {}) => {
  const { label } = props;

  return (
    <React.Fragment>
      {'function' === typeof label ? label({ props, state }) : label}&nbsp;
      {props.required && <IconRequired />}
    </React.Fragment>
  );
};
