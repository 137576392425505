import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Trans } from 'react-i18next';
import { Form } from 'react-bootstrap';

import { Icon } from '../../../Icon';

const Star = ({ index, active, disabled, onClick }) => {
  var number = index + 1;

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      tabIndex={-1}
      className={classNames({
        'osg-star-rating__star': true,
        'osg-star-rating__star--selected': active,
      })}
    >
      <div className="visually-hidden">
        {/* prettier-ignore */}
        <Trans i18nKey="__rateNumberStars" count={number}>
          Rate {{ number }} star
        </Trans>
      </div>
      <Icon icon="star" size="fa-2x" variant={active ? 'solid' : 'light'} />
    </button>
  );
};

Star.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const StarRating = React.forwardRef(
  ({ name, disabled, value, min, max, setValue, onKeyDown }, ref) => (
    <div className="osg-star-rating">
      <Form.Range
        ref={ref}
        name={name}
        disabled={disabled}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        onKeyDown={onKeyDown}
        min={min}
        max={max}
      />
      <div>
        {[...Array(max)].map((n, i) => (
          <Star
            key={i}
            index={i}
            active={i < value}
            disabled={disabled}
            onClick={() => !disabled && setValue(i + 1)}
          />
        ))}
      </div>
      <Form.Text className="visually-hidden">
        {/* prettier-ignore */}
        <Trans i18nKey="__starsOfTotalStars">
          {{ value }} of {{ max }} stars
        </Trans>
      </Form.Text>
    </div>
  )
);

StarRating.displayName = 'StarRating';

StarRating.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};
