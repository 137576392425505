import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

export const RadioGroup = React.forwardRef(
  (
    {
      id,
      type,
      name,
      label,
      disabled,
      autoComplete,
      value,
      options,
      setValue,
      onKeyDown,
    },
    ref
  ) => {
    return (
      <fieldset style={{ width: '100%' }}>
        {label && <legend>{label}</legend>}
        {options.map((option, i) => (
          <Form.Check key={i} type={type} custom>
            <Form.Check.Input
              ref={ref[i]}
              id={`${id}-${i}`}
              type={type}
              name={name}
              disabled={disabled}
              autoComplete={autoComplete}
              value={option.value}
              checked={option.value === value}
              onChange={({ target }) => setValue(target.value)}
              onKeyDown={onKeyDown}
            />
            <Form.Check.Label htmlFor={`${id}-${i}`}>
              {option.label}
            </Form.Check.Label>
          </Form.Check>
        ))}
      </fieldset>
    );
  }
);

RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool.isRequired,
  autoComplete: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
