import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtEncode from 'jwt-encode';
import jwtDecode from 'jwt-decode';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';

import ImpersonationProvider from './helpers/ImpersonationProvider';

import { useTranslation } from 'react-i18next';
import { Header } from './containers/Header';
import { Footer } from './containers/Footer';

const App = React.lazy(() => import('./containers/App/App'));

import { Login } from './containers/Login/Login';
import { PrivateRoute } from './components/PrivateRoute';
import { LoadingMessage } from './components/ResultsMessage';

import { LoginContext } from './actions/userRoles';
import { MenuProvider } from './helpers/MenuHelpers';

// import { useVersionRefresh } from './helpers/Hooks';

import { VerifyTokenQuery } from './graphql/Auth.gql';

import './i18n';
import './index.scss';

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Msg: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    new HttpLink({
      uri: `${process.env.API_URL}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: { watchQuery: { fetchPolicy: 'network-only' } },
});

// const worker = new Worker('./workers/WakeupWorker.js', { type: 'module' });

const osgLoginCookieVal = (() => {
  try {
    return jwtDecode(Cookies.get('osg_login'));
  } catch (e) {
    return null;
  }
})();

const Root = () => {
  const { t } = useTranslation();

  const [currentLogin, setCurrentLogin] = useState(osgLoginCookieVal);
  const [loading, setLoading] = useState(osgLoginCookieVal ? true : false);

  let error = false;

  // Only try to verify token if logged in
  if (osgLoginCookieVal) {
    const result = useQuery(VerifyTokenQuery, {
      client: client,
      onCompleted: data => {
        setCurrentLogin(data.verifyToken?.login);
        setLoading(false);
      },
    });
    error = result.error;
  }

  // Add osg--tabbing class to body if the user is tabbing through to add :focus states
  useEffect(() => {
    const handleNextClick = () => {
      document.body.classList.remove('osg--tabbing');
      window.addEventListener('keydown', handleFirstTab);
    };

    const handleFirstTab = e => {
      if (e.keyCode === 9) {
        // the "I am a keyboard user" key
        document.body.classList.add('osg--tabbing');
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('click', handleNextClick);
      }
    };

    window.addEventListener('keydown', handleFirstTab);

    return () => {
      window.removeEventListener('click', handleNextClick);
      window.removeEventListener('keydown', handleFirstTab);
    };
  }, []); //eslint-disable-line

  // useVersionRefresh(worker);

  useEffect(() => {
    if (loading) {
      return;
    }

    let domain = window.CLIENT_ENV === 'development' ? 'localhost' : '.osg.ca';
    if (window.location.host.indexOf('demo.osg.ca') > -1) {
      domain = window.location.host;
    }

    if (!currentLogin) {
      // Clear login cookie
      Cookies.remove('osg_login', {
        domain,
      });
    } else {
      // Update/set login cookie
      Cookies.set('osg_login', jwtEncode(currentLogin, 'osg_login'), {
        expires: 365,
        secure: true,
        domain,
      });
    }
  }, [currentLogin, loading]);

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-MTZQXR4',
      // This is for the Local Development Environment setup on GTM
      auth: process.env.GTM_AUTH,
      preview: process.env.GTM_PREVIEW,
    });
  }, []);

  return (
    <Router basename={'/account'}>
      <ApolloProvider client={client}>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <LoginContext.Provider value={{ currentLogin, setCurrentLogin }}>
          <MenuProvider loading={loading}>
            <ImpersonationProvider
              routeToOnImpersonate="/"
              routeToOnUnimpersonate="/internal/users-list"
            >
              <a href="#main" className="a11y__skip-main">
                {t('Skip To Content')}
              </a>
              <div className="osg-index">
                <header className="osg-index__header">
                  <Header />
                </header>
                <div className="osg-index__body">
                  {loading ? (
                    <LoadingMessage />
                  ) : error ? (
                    <div>Error: {JSON.stringify(error)}</div>
                  ) : (
                    <Switch>
                      <Route path="/login" component={Login} />
                      <PrivateRoute
                        path="/"
                        component={props => (
                          <React.Suspense fallback={<LoadingMessage />}>
                            <App {...props} />
                          </React.Suspense>
                        )}
                      />
                    </Switch>
                  )}
                </div>
                <div className="osg-index__footer">
                  <Footer />
                </div>
              </div>
            </ImpersonationProvider>
          </MenuProvider>
        </LoginContext.Provider>
      </ApolloProvider>
    </Router>
  );
};

ReactDOM.render(
  <React.Suspense fallback={<LoadingMessage root />}>
    <Root />
  </React.Suspense>,
  document.getElementById('root')
);
