import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { LoadingMessage } from '../components/ResultsMessage';

import { ImpersonateUser } from '../graphql/ImpersonateUser.gql';
import { UnimpersonateUser } from '../graphql/UnimpersonateUser.gql';
import { canImpersonate } from '../actions/userRoles';

const ImpersonationContext = createContext(null);

export function useImpersonationContext() {
  const context = useContext(ImpersonationContext);
  if (!context) {
    throw new Error(
      'useImpersonationContext must be used within the ImpersonationProvider'
    );
  }

  return context;
}

export default function ImpersonationProvider({ children, ...props }) {
  const [impersonateUserMutation] = useMutation(ImpersonateUser);
  const [unimpersonateUserMutation] = useMutation(UnimpersonateUser);

  const history = useHistory();

  const { routeToOnImpersonate, routeToOnUnimpersonate } = props;

  const impersonateSuccess = useCallback(() => {
    window.localStorage.setItem('impersonationActive', JSON.stringify(true));
    window.localStorage.setItem('impersonationLoading', JSON.stringify(true));
    history.push(routeToOnImpersonate);
    document.location.reload();
  }, [history, routeToOnImpersonate]);

  const impersonateFailure = useCallback(() => {
    alert('impersonation failure');
  }, []);

  const unimpersonateSuccess = useCallback(() => {
    window.localStorage.setItem('impersonationActive', JSON.stringify(false));
    window.localStorage.setItem('impersonationLoading', JSON.stringify(true));
    history.push(routeToOnUnimpersonate);
    document.location.reload();
  }, [history, routeToOnUnimpersonate]);

  const unimpersonateFailure = useCallback(() => {
    alert('unimpersonation failure');
  }, []);

  const impersonate = useCallback(
    username => {
      canImpersonate() &&
        impersonateUserMutation({ variables: { username: username } }).then(
          impersonateSuccess,
          impersonateFailure
        );
    },
    [impersonateFailure, impersonateSuccess, impersonateUserMutation]
  );

  const unimpersonate = useCallback(() => {
    unimpersonateUserMutation().then(
      unimpersonateSuccess,
      unimpersonateFailure
    );
  }, [unimpersonateFailure, unimpersonateSuccess, unimpersonateUserMutation]);

  const impersonationActive = JSON.parse(
    window.localStorage.getItem('impersonationActive')
  );
  const impersonationLoading = JSON.parse(
    window.localStorage.getItem('impersonationLoading')
  );

  useEffect(() => {
    if (impersonationLoading) {
      window.localStorage.setItem('impersonationLoading', false);
    }
  });

  return (
    <ImpersonationContext.Provider
      value={{ impersonationActive, impersonate, unimpersonate }}
    >
      {impersonationLoading ? <LoadingMessage /> : children}
    </ImpersonationContext.Provider>
  );
}
