import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getI18n, Trans } from 'react-i18next';
import { Form, InputGroup } from 'react-bootstrap';

import { StarRating } from './Input/StarRating'; // 2kb
import { RadioGroup } from './Input/RadioGroup'; // 1kb
import { File } from './Input/File';

const PasswordStrengthMeter = React.lazy(() =>
  import('./Input/PasswordStrengthMeter')
);
const Phone = React.lazy(() => import('./Input/Phone'));
const Region = React.lazy(() => import('./Input/Region'));
const Country = React.lazy(() => import('./Input/Country'));
const DateInput = React.lazy(() => import('./Input/Date'));

import { randomStringGenerator } from '../../helpers/Objects';
import { getLabel } from './helpers';

const InputSkeleton = () => <div className="osg-input--skeleton" />;

export class Input extends React.Component {
  // Errors
  static REQUIRED = 'required';
  static FORMAT = 'format';
  static CUSTOM = 'custom';
  static UPLOAD = 'upload';

  // Render as types
  static INPUT = 'input';
  static TEXTAREA = 'textarea';
  static SELECT = 'select';

  // Input types
  static EMAIL = 'email';
  static PASSWORD = 'password';
  static TEXT = 'text';
  static RANGE = 'range';
  static NUMBER = 'number';
  static RADIO = 'radio';
  static FILE = 'file';
  static CHECKBOX = 'checkbox';
  static PHONE = 'tel';
  static DATE = 'date';

  // Input variants
  static PASSWORD_STRENGTH_METER = 'password-strength-meter';
  static STAR_RATING = 'star-rating';
  static SWITCH = 'switch';
  static REGION = 'region';
  static COUNTRY = 'country';

  // Default regexes for field validation
  /* eslint-disable */
  /* prettier-ignore */
  static emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static phoneRegex = /^(\d){10}$/;
  /* eslint-enable */
  // Default errors
  static errors = {
    format: {
      /* prettier-ignore */
      [Input.EMAIL]: <Trans>Please enter a valid email.</Trans>,
      /* prettier-ignore */
      [Input.PHONE]: <Trans>Please enter a valid phone number.</Trans>,
    },
    /* prettier-ignore */
    upload: <Trans>Error uploading file. Please try again.</Trans>,
  };

  static displayName = 'Input';

  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    text: PropTypes.node,
    autoFocus: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    autoComplete: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    format: PropTypes.string,
    style: PropTypes.object,
    isInteger: PropTypes.bool.isRequired,
    checkValidity: PropTypes.func,
    shouldRenderInitialValue: PropTypes.bool.isRequired,
    shouldSubmitInitialValue: PropTypes.bool.isRequired,
    inputRef: PropTypes.object,
    isCanada: PropTypes.bool,
    prepend: PropTypes.node,
    append: PropTypes.node,
    ariaAttributes: PropTypes.object,
    tabindex: PropTypes.number,
    validateOnBlur: PropTypes.bool,

    as: PropTypes.oneOf([Input.INPUT, Input.TEXTAREA, Input.SELECT]).isRequired,

    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),

    label: PropTypes.oneOfType([
      PropTypes.node.isRequired,
      PropTypes.func.isRequired,
    ]).isRequired,

    type: PropTypes.oneOf([
      Input.EMAIL,
      Input.PASSWORD,
      Input.RANGE,
      Input.TEXT,
      Input.NUMBER,
      Input.RADIO,
      Input.FILE,
      Input.CHECKBOX,
      Input.PHONE,
      Input.DATE,
    ]).isRequired,

    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),

    variant: PropTypes.oneOf([
      Input.PASSWORD_STRENGTH_METER,
      Input.STAR_RATING,
      Input.SWITCH,
      Input.REGION,
      Input.COUNTRY,
    ]),

    errors: PropTypes.shape({
      [Input.REQUIRED]: PropTypes.string,
      [Input.FORMAT]: PropTypes.string,
      [Input.CUSTOM]: PropTypes.string,
      [Input.UPLOAD]: PropTypes.string,
    }),

    // Textarea
    rows: PropTypes.string,

    // Range
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,

    // Date
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),

    group: PropTypes.shape({
      className: PropTypes.string,
      as: PropTypes.object,
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
    }),
  };

  static defaultProps = {
    as: Input.INPUT,
    type: Input.TEXT,
    autoFocus: false,
    disabled: false,
    required: false,
    autoComplete: 'on',
    min: 0,
    max: 1,
    isInteger: false,
    shouldRenderInitialValue: true,
    shouldSubmitInitialValue: true,
    errors: {},
    validateOnBlur: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      key: randomStringGenerator(),
      id: props.id || randomStringGenerator(),
      value: this.getDefaultValue(),
      dirtied: false,
      isInvalid: false,
      error: false,
    };

    if (Input.RADIO === props.type) {
      this.input = [];
      props.options.forEach(() => this.input.push(React.createRef()));
    } else this.input = React.createRef();

    if (props.inputRef) props.inputRef.current = this;
  }

  // Get initial value based on the input type.
  getInitialValue = () => (this.shouldParseInt() ? 0 : '');

  // Determine if the input value should be an integer.
  shouldParseInt = () =>
    Input.NUMBER === this.props.type ||
    Input.RANGE === this.props.type ||
    this.props.isInteger;

  // Used for reseting forms to default values and initializing the input class.
  getDefaultValue = () => {
    const { props } = this,
      { defaultValue } = props;

    if (undefined === defaultValue || null === defaultValue)
      return this.getInitialValue();
    return this.shouldParseInt() ? parseInt(defaultValue) : defaultValue;
  };

  // Used to check if the current input field type should be validated.
  shouldValidate = () => {
    const { props } = this;

    return (
      Input.PASSWORD_STRENGTH_METER !== props.variant &&
      Input.RADIO !== props.type
    );
  };

  // Used by input compoents to set value and validity of class.
  setValue = (
    value,
    isInvalid = this.state.isInvalid,
    error = this.state.error
  ) =>
    this.setState({ value, isInvalid, error, dirtied: true }, () => {
      if (this.props.onChange) {
        this.props.onChange({ value: this.state.value });
      }
    });

  setValidity = (force = false) => this.validate(true, force);
  validate = (setValidity = false, force) => {
    const { state, props } = this;

    // Short circuit validation when the field should not be validated.
    if (!force && !this.shouldValidate()) return !state.isInvalid;

    const { value } = state;

    let isInvalid =
      Input.PASSWORD_STRENGTH_METER === props.variant ? state.isInvalid : false;

    if (props.required && !value) isInvalid = Input.REQUIRED;
    else if (
      value &&
      Input.EMAIL === props.type &&
      !Input.emailRegex.test(value)
    )
      isInvalid = Input.FORMAT;
    else if (
      value &&
      Input.PHONE === props.type &&
      !Input.phoneRegex.test(value)
    )
      isInvalid = Input.FORMAT;
    else if (
      props.required ||
      ('' !== value && 'number' === typeof value && !isNaN(value))
    ) {
      if (props.format && !new RegExp(props.format).test(value))
        isInvalid = Input.FORMAT;
      else if (props.checkValidity && !props.checkValidity(value))
        isInvalid = Input.CUSTOM;
    }

    if ((!state.error || force) && (setValidity || state.isInvalid))
      this.setState({ isInvalid });
    return !isInvalid;
  };

  onChange = e =>
    this.setState(
      {
        dirtied: true,
        value: this.shouldParseInt()
          ? parseInt(e.target.value)
          : e.target.value,
      },
      () => {
        this.validate();
        if (this.props.onChange) {
          this.props.onChange({ value: this.state.value });
        }
      }
    );

  onToggle = () =>
    this.setState({ value: !this.state.value }, () => {
      this.validate();
      if (this.props.onChange) {
        this.props.onChange({ value: this.state.value });
      }
    });

  onBlur = ({ target }) => {
    const { state } = this,
      { value } = state;

    if (state.dirtied || this.props.required) {
      if (value && !this.shouldParseInt() && Input.CHECKBOX !== target.type) {
        this.setState({ value: value?.trim() });
      }
      if (this.props.validateOnBlur) {
        this.setValidity();
      }
    }
    if (this.props.onBlur) this.props.onBlur({ target });
  };

  focus = () => {
    const { props } = this;

    if (Input.RADIO === props.type) this.input[0].current.focus();
    else if (Input.PASSWORD_STRENGTH_METER === props.variant)
      this.input.current.reactPasswordStrengthInput.focus();
    else if (Input.PHONE === props.type)
      this.input.current.numberInputRef.focus();
    else this.input.current.focus();
  };

  reset = () => {
    const { props } = this;

    if (Input.FILE === props.type)
      this.setState({ key: randomStringGenerator() });
  };

  render() {
    const { state, props } = this,
      { language } = getI18n(),
      { id, value, isInvalid } = state,
      { label, type, errors, group, prepend, append } = props;

    const inputProps = {
      inputKey: state.key,
      inputRef: this.input,
      id,
      type,
      name: props.name,
      label: label ? getLabel(props, state) : undefined,
      placeholder: props.placeholder,
      value,
      safeValue:
        (!state.dirtied &&
          this.getInitialValue() === value &&
          !props.shouldRenderInitialValue) ||
        ('number' === typeof value && isNaN(value))
          ? ''
          : value,
      disabled: props.disabled,
      isInvalid: isInvalid && true,
      autoFocus: props.autoFocus, // eslint-disable-line
      autoComplete: props.autoComplete,
      min: props.min,
      max: props.max,
      minDate: props.minDate,
      maxDate: props.maxDate,
      required: props.required,
      variant: props.variant,
      as: props.as,
      size: props.size,
      rows: props.rows,
      style: props.style,
      options: props.options,
      setValue: this.setValue,
      onChange: this.onChange,
      onToggle: this.onToggle,
      onKeyDown: props.onKeyDown,
      onBlur: this.onBlur,
      onStart: props.onStart,
      onUpdate: props.onUpdate,
      onComplete: props.onComplete,
      onError: props.onError,
      isCanada: props.isCanada,
      ...props.ariaAttributes,
      ...(props.tabindex && { tabIndex: props.tabindex }),
    };

    const groupClasses = classNames({
      'osg-input': true,
      [`${group?.className}`]: group?.className,
      'osg-input--auto-focus': props.autoFocus,
      'osg-input--required': props.required,
    });

    return (
      <Form.Group
        controlId={id}
        className={groupClasses}
        as={group?.as}
        xs={group?.xs}
        sm={group?.sm}
        md={group?.md}
        lg={group?.lg}
      >
        {label && Input.RADIO !== type && Input.CHECKBOX !== type && (
          <Form.Label>{getLabel(props, state)}</Form.Label>
        )}
        {prepend || append ? (
          <InputGroup className={isInvalid ? 'is-invalid' : undefined}>
            {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
            <React.Suspense fallback={<InputSkeleton />}>
              <FormInput {...inputProps} />
            </React.Suspense>
            {append && <InputGroup.Text>{append}</InputGroup.Text>}
          </InputGroup>
        ) : (
          <React.Suspense fallback={<InputSkeleton />}>
            <FormInput {...inputProps} />
          </React.Suspense>
        )}
        <Form.Text>{props.text}</Form.Text>
        <Form.Control.Feedback type="invalid">
          {Input.REQUIRED === isInvalid
            ? errors.required
            : Input.FORMAT === isInvalid
            ? errors.format || Input.errors.format[type]
            : Input.CUSTOM === isInvalid
            ? errors.custom
            : Input.UPLOAD === isInvalid
            ? errors.upload || Input.erorrs.upload
            : state.error[language] || ''}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
}

const FormInput = ({
  inputKey,
  inputRef,
  id,
  type,
  label,
  placeholder,
  value,
  safeValue,
  isInvalid,
  min,
  max,
  minDate,
  maxDate,
  required,
  variant,
  as,
  rows,
  style,
  options,
  setValue,
  onChange,
  onToggle,
  onStart,
  onUpdate,
  onComplete,
  onError,
  isCanada,
  ...props
}) =>
  Input.PASSWORD_STRENGTH_METER === variant ? (
    <PasswordStrengthMeter
      {...props}
      ref={inputRef}
      id={id}
      isInvalid={isInvalid}
      setValue={setValue}
    />
  ) : Input.STAR_RATING === variant ? (
    <StarRating
      {...props}
      ref={inputRef}
      type={Input.RANGE}
      value={value}
      min={min}
      max={max}
      setValue={setValue}
    />
  ) : Input.RADIO === type ? (
    <RadioGroup
      {...props}
      ref={inputRef}
      id={id}
      type={Input.RADIO}
      label={label}
      value={value}
      options={options}
      setValue={setValue}
    />
  ) : Input.FILE === type ? (
    <File
      {...props}
      key={inputKey}
      ref={inputRef}
      isInvalid={isInvalid}
      setValue={setValue}
      onStart={onStart}
      onUpdate={onUpdate}
      onComplete={onComplete}
      onError={onError}
    />
  ) : type === Input.PHONE ? (
    <Phone
      {...props}
      ref={inputRef}
      type={Input.PHONE}
      value={value}
      isInvalid={isInvalid}
      setValue={setValue}
    />
  ) : type === Input.DATE ? (
    <DateInput
      {...props}
      ref={inputRef}
      value={value}
      isInvalid={isInvalid}
      min={minDate}
      max={maxDate}
      setValue={setValue}
    />
  ) : variant === Input.REGION ? (
    <Region
      {...props}
      ref={inputRef}
      value={value}
      isInvalid={isInvalid}
      required={required}
      setValue={setValue}
      isCanada={isCanada}
    />
  ) : variant === Input.COUNTRY ? (
    <Country
      {...props}
      ref={inputRef}
      value={value}
      isInvalid={isInvalid}
      setValue={setValue}
    />
  ) : as === Input.SELECT ? (
    <Form.Select
      {...props}
      ref={inputRef}
      value={value}
      isInvalid={isInvalid}
      onChange={onChange}
    >
      {as === Input.SELECT &&
        options.map(({ value, label }, key) => (
          <option key={key} value={value}>
            {label}
          </option>
        ))}
    </Form.Select>
  ) : type === Input.CHECKBOX ? (
    <Form.Check
      {...props}
      ref={inputRef}
      type={variant || Input.CHECKBOX}
      label={label}
      checked={value}
      isInvalid={isInvalid}
      onChange={onToggle}
    />
  ) : type === Input.RANGE ? (
    <Form.Range
      {...props}
      ref={inputRef}
      value={safeValue}
      onChange={onChange}
      min={min}
      max={max}
    />
  ) : (
    <Form.Control
      {...props}
      ref={inputRef}
      type={Input.EMAIL === type ? Input.TEXT : type}
      placeholder={placeholder}
      value={safeValue}
      isInvalid={isInvalid}
      onChange={onChange}
      as={as}
      rows={rows}
      style={{
        resize: 'none',
        ...style,
      }}
    />
  );
