import React from 'react';

const LoginContext = React.createContext({
  currentLogin: {},
  setCurrentLogin: () => {},
});

const getCookie = key =>
  document.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`)?.[2] || '';

const getRole = () =>
  parseInt(atob(unescape(getCookie('_lms_role').split('--')[0])));

const SUPER_MASTER_ROLE = 10, // eslint-disable-line no-unused-vars
  MASTER_ROLE = 100,
  CM_DISTRIBUTOR_SALES_REP_ROLE = 400,
  SALES_REP_ROLE = 500, // eslint-disable-line no-unused-vars
  GLOBAL_PUBLISHER_ROLE = 600,
  DISTRIBUTOR_ROLE = 650,
  ADMIN_ROLE = 700,
  ADMIN_LEVEL_2_ROLE = 720,
  ADMIN_LEVEL_3_ROLE = 730,
  TRAINEE_ROLE = 800;

const isLoggedIn = (role = getRole()) => role > 0;

const isMaster = (role = getRole()) => role < CM_DISTRIBUTOR_SALES_REP_ROLE;

const isAdmin = (role = getRole()) => role < TRAINEE_ROLE;

const isDistributor = (role = getRole()) => role === DISTRIBUTOR_ROLE;

const isSalesRep = (role = getRole()) =>
  role > MASTER_ROLE && role < GLOBAL_PUBLISHER_ROLE;

const isGlobalPublisher = (role = getRole()) => role === GLOBAL_PUBLISHER_ROLE;

const isPublisher = () => isMaster() || isGlobalPublisher();

const isAnyKindOfAdmin = (role = getRole()) =>
  role === ADMIN_ROLE ||
  role === ADMIN_LEVEL_2_ROLE ||
  role === ADMIN_LEVEL_3_ROLE;

const canImpersonate = () => isMaster() || isGlobalPublisher();

export {
  LoginContext,
  isLoggedIn,
  isMaster,
  isAdmin,
  isDistributor,
  isSalesRep,
  isGlobalPublisher,
  isPublisher,
  isAnyKindOfAdmin,
  canImpersonate,
};
