import React, { useContext } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ImpersonateAlert } from '../../components/ImpersonateAlert';
// import { NavbarAlert } from '../../components/NavbarAlert';
import { HeaderBase } from '../../components/HeaderBase';
import { TranslateSwitch } from '../../components/TranslateSwitch';

const MainNav = React.lazy(() => import('../MainNav'));

import { LogOut } from '../../components/LogOut';

import { LoginContext } from '../../actions/userRoles';
import { MenuContext } from '../../helpers/MenuHelpers';

import { useImpersonationContext } from '../../helpers/ImpersonationProvider';

export const Header = withRouter(({ user, history }) => {
  const { t } = useTranslation();
  const { impersonationActive } = useImpersonationContext();
  const { wordpress } = useContext(MenuContext);

  const { currentLogin } = useContext(LoginContext);
  const fullName = user
    ? user.fullName
    : currentLogin?.firstName + ' ' + currentLogin?.lastName;

  let mobileMenu = currentLogin
    ? (mobileMenu = (
        <React.Suspense
          fallback={
            <div className="osg-main-nav osg-main-nav--slideout">
              <div className="flex-column nav">Loading Menu...</div>
            </div>
          }
        >
          <MainNav slideout />
        </React.Suspense>
      ))
    : null;

  return (
    <React.Fragment>
      {impersonationActive && <ImpersonateAlert fullName={fullName} />}
      <HeaderBase data={wordpress.header} mobile={mobileMenu}>
        {!currentLogin ? (
          <div className="osg-header-base__menu__account ms-auto">
            <a href="/online/login" className="nav-link nav-link--account-link">
              {t('Login')}
            </a>
          </div>
        ) : (
          <div className="osg-header-base__menu__account">
            <Nav.Link href="/account/" className="nav-link--account-link">
              <React.Fragment>
                <span>{fullName}</span>
              </React.Fragment>
            </Nav.Link>
            <NavDropdown
              renderMenuOnMount={true}
              align="end"
              title={
                <span className="sr-only">{t('Open Account Dropdown')}</span>
              }
            >
              <React.Fragment>
                <NavLink
                  to="/profile"
                  className="dropdown-item"
                  activeClassName="_active"
                >
                  {t('View Profile')}
                </NavLink>

                <NavLink
                  to="/online-courses"
                  className="dropdown-item"
                  activeClassName="_active"
                >
                  {t('My Courses')}
                </NavLink>
              </React.Fragment>

              <TranslateSwitch dropdown />
              <NavDropdown.Divider />
              <LogOut history={history} dropdown />
            </NavDropdown>
          </div>
        )}
      </HeaderBase>
    </React.Fragment>
  );
});

{
  /* TODO: Determine when and how to show the alert message */
}
{
  /* {true && (
        <NavbarAlert
          message={t(
            'Support will be unavailable from Dec 24 to Jan 3 for holidays.'
          )}
        />
      )} */
}
