import React from 'react';
import { Nav } from 'react-bootstrap';

const FooterMenu = ({ data }) => {
  if (!data)
    return (
      <React.Fragment>
        {Array.from({ length: 4 }, (_, i) => (
          <Nav key={i} className="flex-column osg-footer-base__nav">
            <Nav.Link
              className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
              disabled
            ></Nav.Link>
            <Nav.Link
              className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
              disabled
            ></Nav.Link>
            <Nav.Link
              className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
              disabled
            ></Nav.Link>
            <Nav.Link
              className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
              disabled
            ></Nav.Link>
            <Nav.Link
              className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
              disabled
            ></Nav.Link>
            <Nav.Link
              className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
              disabled
            ></Nav.Link>
          </Nav>
        ))}
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {Object.entries(data).map(([key, value], index) => {
        return (
          <Nav key={index} className="flex-column osg-footer-base__nav">
            <Nav.Link
              className="osg-footer-base__nav__header"
              href={value.href}
            >
              {key}
            </Nav.Link>

            {Object.entries(value.children).map(([key2, value2], index2) => {
              return (
                <Nav.Link key={index2} href={value2.href}>
                  {key2}
                </Nav.Link>
              );
            })}
          </Nav>
        );
      })}
    </React.Fragment>
  );
};

const FooterWidget = ({ data }) => {
  if (!data)
    return (
      <React.Fragment>
        <Nav className="flex-column osg-footer-base__widget">
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
          <Nav.Link
            className="osg-footer-base__nav__item osg-footer-base__nav__item--skeleton"
            disabled
          ></Nav.Link>
        </Nav>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {data.map((value, index) => {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: value }}></div>
        );
      })}
    </React.Fragment>
  );
};

export const FooterBase = props => {
  return (
    <footer
      className="osg-footer-base"
      role="contentinfo"
      aria-label="Site Footer"
    >
      <div className="osg-footer-base__container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8 osg-footer-base__menus">
            <FooterMenu data={props.data?.menu} />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <FooterWidget data={props.data?.widgets} />
          </div>
        </div>

        <div className="osg-footer-base__copyright">
          {props.data?.info &&
            Object.values(props.data?.info).map((value, index) => {
              return <div key={index}>{value}</div>;
            })}
        </div>
      </div>
    </footer>
  );
};
