import React from 'react';
import PropTypes from 'prop-types';
import { getI18n } from 'react-i18next';
import { Alert, Form } from 'react-bootstrap';

import { Input } from './index';

export class SubmitForm extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    variables: PropTypes.object,
    mutation: PropTypes.shape({
      key: PropTypes.string.isRequired,
      variables: PropTypes.object,
      diff: PropTypes.object,
      mutate: PropTypes.func.isRequired,
      onMutate: PropTypes.func.isRequired,
      skip: PropTypes.func,
      onSkip: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };

    this.inputs = {};
  }

  handleSuccess = (response, variables) => {
    const { mutation } = this.props,
      data = response.data[mutation.key];

    if (data.errors) this.handleErrors(data.errors);
    else mutation.onMutate(data, variables);
  };

  // TODO: handle server unhandled errors
  handleFailure = (...all) => console.log(all);

  parseErrors = errors => {
    if (!errors) return [];

    const parsed = [];
    let first = true;
    errors.forEach(error => {
      const { field, message } = error;

      if (field) {
        const { current } = this.inputs[field] || {};

        if (current) {
          current.setState({ isInvalid: true, error: message });

          if (first) {
            first = false;
            current.focus();
          }
        } else parsed.push(`[${field}]: ${message}`);
      } else parsed.push(error.message);
    });

    return parsed;
  };

  handleErrors = errors => this.setState({ errors: this.parseErrors(errors) });

  onSubmit = e => {
    e.preventDefault();

    const { mutation } = this.props,
      { diff } = mutation,
      variables = { ...(mutation.variables || {}) };

    let valid = true,
      first = true;
    Object.values(this.inputs).forEach(({ current }) => {
      if (current) {
        let { props, state } = current,
          { value } = state,
          initialValue = current.getInitialValue();

        valid = current.setValidity(true) && valid;

        if (
          (state.dirtied || value !== initialValue) &&
          (initialValue !== value || props.shouldSubmitInitialValue)
        ) {
          variables[props.name] =
            '' === value || ('number' === typeof value && isNaN(value))
              ? null
              : value;
        }

        if (!valid && first) {
          first = false;
          current.focus();
        }
      }
    });

    if (diff) {
      Object.entries(variables).forEach(([key, value]) => {
        if (diff[key] === value && !mutation.variables?.[key])
          delete variables[key];
      });
    }

    if (valid) {
      if (mutation.skip && mutation.skip(variables)) {
        if (mutation.onSkip) mutation.onSkip();
      } else
        mutation
          .mutate({ variables })
          .then(
            response => this.handleSuccess(response, variables),
            this.handleFailure
          );
    }
  };

  onReset = e => {
    e.preventDefault();

    let first = true;
    Object.values(this.inputs).forEach(({ current }) => {
      if (first) {
        current.focus();
        first = false;
      }

      current.setState(
        { value: current.getDefaultValue(), isInvalid: false, error: false },
        current.reset
      );
    });
  };

  renderHelper = children => {
    // If children is not an array or not an Input React component, then its a string or undefined.
    if ('object' !== typeof children) return children;

    if (Array.isArray(children)) {
      return children.reduce((children, child, key) => {
        if (!child) return children;

        const component = this.renderHelper(child);
        component &&
          children.push(component.key ? component : { ...component, key });
        return children;
      }, []);
    }
    const { inputs } = this;
    let { props } = children;

    if ('Input' !== children?.type?.displayName) {
      if ('Address' === children?.type?.displayName) {
        inputs['street'] = React.createRef();
        inputs['street2'] = React.createRef();
        inputs['city'] = React.createRef();
        inputs['province'] = React.createRef();
        inputs['postalCode'] = React.createRef();
        inputs['country'] = React.createRef();
        return {
          ...children,
          props: {
            ...props,
            escape: this.props.escape,
            refs: {
              street1: inputs['street'],
              street2: inputs['street2'],
              city: inputs['city'],
              province: inputs['province'],
              postalCode: inputs['postalCode'],
              country: inputs['country'],
            },
            children: this.renderHelper(props.children),
          },
        };
      }

      return {
        ...children,
        props: {
          ...props,
          children: this.renderHelper(props.children),
        },
      };
    }

    const { name } = props;

    if (!Object.keys(inputs).length) props = { ...props, autoFocus: true };
    if (!inputs[name]) inputs[name] = React.createRef();

    return (
      <Input
        {...props}
        ref={inputs[name]}
        onKeyDown={(...all) => {
          if (this.props.escape) this.props.escape(...all);
          if (props.onKeyDown) props.onKeyDown(...all);
        }}
      />
    );
  };

  render() {
    const { props } = this,
      { errors } = this.state,
      { language } = getI18n();

    return (
      <Form
        id={props.id}
        className={['osg-submit-form', props.className]}
        onSubmit={this.onSubmit}
        onReset={this.onReset}
      >
        {errors.length ? (
          <Alert variant="danger">
            {errors.map((error, key) => (
              <span className="osg-submit-form__error" key={key}>
                {error[language]}&nbsp;
              </span>
            ))}
          </Alert>
        ) : null}
        {this.renderHelper(props.children)}
      </Form>
    );
  }
}
