import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-xhr-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
const enTranslation = require('../public/locales/en/translation.json');

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    compatibilityJSON: 'v3',
    supportedLngs: ['en', 'fr'],
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    lowerCaseLng: true,
    debug: false,
    nsSeparator: false,
    keySeparator: false,
    load: 'languageOnly',
    backend: {
      backends: [
        resourcesToBackend(lng => {
          if (lng === 'en') {
            return enTranslation;
          }
        }),
        Backend,
      ],

      backendOptions: [
        {},
        {
          loadPath:
            process.env.NODE_ENV !== 'production'
              ? `/locales/{{lng}}/{{ns}}.json`
              : `/account/locales/{{lng}}/{{ns}}.json`,
        },
      ],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ',',
    },
    react: {
      bindI18n: 'languageChanged',
    },
  });

i18n.on('initialized', function () {
  if (['fr-be', 'fr-ca', 'fr-lu', 'fr-ch'].includes(i18n.language)) {
    i18n.changeLanguage('fr');
  } else if (
    [
      'en-au',
      'en-bz',
      'en-ca',
      'en-ie',
      'en-jm',
      'en-nz',
      'en-za',
      'en-tt',
      'en-gb',
      'en-us',
    ].includes(i18n.language)
  ) {
    i18n.changeLanguage('en');
  }
});

export default i18n;
