import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

import { Input } from './Input';

const postalCodeRegex =
  '^[A-Za-z]\\d[A-Za-z]\\d[A-Za-z]\\d$|^[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d$';
const zipCodeRegex = '^\\d{5}-\\d{4}$|^\\d{9}$|^\\d{5}$';

export const Address = ({
  address,
  saving,
  disabled,
  required,
  refs,
  escape,
}) => {
  const { t } = useTranslation();
  const street1Ref = useRef();
  const street2Ref = useRef();
  const cityRef = useRef();
  const provinceRef = useRef();
  const postalCodeRef = useRef();
  const countryRef = useRef();
  const [isCanada, setIsCanada] = useState('Canada' === address.country);
  const [isRequired, setIsRequired] = useState(
    '' !==
      (address.street1 ||
        address.street2 ||
        address.city ||
        address.province ||
        address.postalCode)
  );

  const onBlur = () => {
    let required = false;

    if (street1Ref.current && '' !== street1Ref.current.state.value)
      required = true;
    if (street2Ref.current && '' !== street2Ref.current.state.value)
      required = true;
    if (cityRef.current && '' !== cityRef.current.state.value) required = true;
    if (provinceRef.current && '' !== provinceRef.current.state.value)
      required = true;
    if (postalCodeRef.current && '' !== postalCodeRef.current.state.value)
      required = true;

    if (isRequired !== required) {
      setIsRequired(required);
    }
  };

  const onKeyDown = (...all) => {
    if (escape) escape(...all);
  };

  useEffect(() => {
    if (!isRequired) {
      street1Ref.current.setValidity(true);
      cityRef.current.setValidity(true);
      provinceRef.current.setValidity(true);
      postalCodeRef.current.setValidity(true);
    }
  }, [isRequired]);

  useEffect(() => {
    postalCodeRef.current.setValidity(true);
  }, [isCanada]);

  return (
    <React.Fragment>
      <Input
        ref={refs['street1']}
        inputRef={street1Ref}
        name="street1"
        label={t('Street Address')}
        defaultValue={address.street1}
        disabled={saving || disabled}
        required={required || isRequired}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus // eslint-disable-line
        errors={{
          required: t('Please enter street address.'),
        }}
      />
      <Input
        ref={refs['street2']}
        inputRef={street2Ref}
        name="street2"
        label={t('Street Address Line 2')}
        defaultValue={address.street2}
        disabled={saving || disabled}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
      <Input
        ref={refs['city']}
        inputRef={cityRef}
        name="city"
        label={t('City')}
        defaultValue={address.city}
        disabled={saving || disabled}
        required={required || isRequired}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        errors={{
          required: t('Please enter city.'),
        }}
      />
      <Row>
        <Input
          as="select"
          ref={refs['province']}
          inputRef={provinceRef}
          name="province"
          label={isCanada ? t('Province') : t('State')}
          defaultValue={address.province}
          disabled={saving || disabled}
          variant={Input.REGION}
          isCanada={isCanada}
          required={required || isRequired}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          group={{
            as: Col,
          }}
          errors={{
            required: t('Please enter region.'),
          }}
        />
        <Input
          ref={refs['postalCode']}
          inputRef={postalCodeRef}
          name="postalCode"
          label={isCanada ? t('Postal Code') : t('Zip Code')}
          defaultValue={address.postalCode}
          disabled={saving || disabled}
          required={required || isRequired}
          format={isCanada ? postalCodeRegex : zipCodeRegex}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          group={{
            as: Col,
          }}
          errors={{
            required: isCanada
              ? t('Please enter postal code.')
              : t('Please enter zip code.'),
            format: isCanada
              ? t('Please enter a valid postal code ex. A1A 1A1 or A1A1A1')
              : t(
                  'Please enter a valid zip code ex. 12345. 12345-6789 or 123456789'
                ),
          }}
        />
      </Row>
      <Input
        ref={refs['country']}
        inputRef={countryRef}
        name="country"
        label={t('Country')}
        defaultValue={address.country || 'Canada'}
        disabled={saving || disabled}
        variant={Input.COUNTRY}
        required={required || isRequired}
        onChange={({ value }) => setIsCanada('Canada' === value)}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        errors={{
          required: t('Please enter country.'),
        }}
      />
    </React.Fragment>
  );
};

Address.displayName = 'Address';

Address.propTypes = {
  address: PropTypes.shape({
    street1: PropTypes.string.isRequired,
    street2: PropTypes.string,
    city: PropTypes.string.isRequired,
    province: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  }).isRequired,
  saving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  escape: PropTypes.func,
  refs: PropTypes.shape({
    street1: PropTypes.object.isRequired,
    street2: PropTypes.object,
    city: PropTypes.object.isRequired,
    province: PropTypes.object.isRequired,
    postalCode: PropTypes.object.isRequired,
    country: PropTypes.object.isRequired,
  }),
};

Address.defaultProps = {
  address: {
    street1: '',
    street2: '',
    city: '',
    province: '',
    postalCode: '',
    country: 'Canada',
  },
  disabled: false,
  saving: false,
  required: false,
};
