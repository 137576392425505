import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const Icon = ({ icon, className, variant, size }) => {
  const classes = classNames({
    fas: variant === 'solid',
    // 'fab': variant === 'brand',
    far: variant === 'regular',
    fal: variant === 'light',
    fad: variant === 'duotone',
    [`fa-${icon}`]: icon,
    [`${size}`]: size,
    [className]: className,
  });

  return icon ? <i className={classes}></i> : false;
};

Icon.propTypes = {
  /** Use Font Awesome 5 Pro Icon names, if left blank, nothing renders https://fontawesome.com/icons?d=gallery */
  icon: PropTypes.string,
  /** Size is a multiplier of it's parent font-size */
  size: PropTypes.oneOf([
    'fa-xs',
    'fa-sm',
    'fa-lg',
    'fa-2x',
    'fa-3x',
    'fa-5x',
    'fa-7x',
    'fa-10x',
  ]),
  /** Changes icon style. NOTE: Not all icon names are available in all variants */
  variant: PropTypes.oneOf([
    'solid',
    'regular',
    // 'brand',
    'light',
    'duotone',
  ]),
  /** Append additional classes. Example: "fa-fw" to make the icon have a fixed width */
  className: PropTypes.string,
};

Icon.defaultProps = {
  icon: null,
  className: null,
  variant: 'regular',
  size: null,
};

export const IconRequired = () => {
  const { t } = useTranslation();
  return (
    <abbr title={t('required')}>
      <Icon icon="asterisk" variant="solid" className="fa-xs text-danger" />
    </abbr>
  );
};

export const IconSaving = ({ left, right, className }) => {
  const classes = classNames({
    'fa-fw': true,
    'mx-2': !left && !right,
    'me-2': left,
    'ms-2': right,
    'fa-spin': true,
    [className]: className,
  });
  return <Icon icon="spinner-third" variant="solid" className={classes} />;
};
