import React, { useState } from 'react';
import { Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import logo from '../../assets/icon-logo.svg';
const logo =
  "data:image/svg+xml;xml,%3Csvg class='custom-logo' alt='OSG' width='80' height='80' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' style='max-width: 60px; max-height: 60px;'%3E%3Cstyle%3E.st0%7Bfill:%230083C3%7D.st1%7Bfill:%23FFF%7D%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M80,40c0,22.9-19.3,41.4-42.5,39.9c-20-1.2-36.1-17.3-37.4-37.4C-1.4,19.3,17.1,0,40,0C62.1,0,80,17.9,80,40'%3E%3C/path%3E%3Cpath class='st1' d='M17.9,46.5c1.7,0,3.1-0.6,4.2-1.8c1.1-1.2,1.7-2.7,1.7-4.4s-0.6-3.2-1.7-4.4c-1.1-1.2-2.6-1.8-4.2-1.8%0Ac-1.7,0-3.1,0.6-4.2,1.8c-1.1,1.2-1.7,2.7-1.7,4.4c0,1.7,0.6,3.2,1.7,4.4C14.8,45.9,16.2,46.5,17.9,46.5 M17.9,29.1%0Ac3.2,0,5.9,1.1,8.1,3.2c2.2,2.1,3.3,4.8,3.3,7.9S28.1,46,26,48.2c-2.2,2.1-4.8,3.2-8.1,3.2c-3.2,0-5.9-1.1-8.1-3.2%0Ac-2.2-2.2-3.2-4.8-3.2-7.9c0-3.1,1.1-5.8,3.2-7.9C12,30.2,14.7,29.1,17.9,29.1'%3E%3C/path%3E%3Cpath class='st1' d='M45.5,30.5c1.9,1,3.3,3,3.5,5.7h-5.4c-0.3-1.8-1.5-2.7-3.5-2.7c-1.9,0-3.1,0.8-3.1,2.1c0,1.1,0.8,1.6,2.5,2%0Al3,0.7c4.2,1,6.5,2.6,6.5,6.1c0,2.1-0.8,3.8-2.4,5.1c-1.6,1.2-3.8,1.9-6.5,1.9c-2.6,0-4.8-0.6-6.6-1.9c-1.8-1.3-2.8-3.2-3.1-5.7%0Ah5.3c0.3,1.9,2,3,4.5,3c2,0,3-0.7,3-1.9c0-0.9-0.7-1.5-2.3-1.8l-3.5-0.8c-4.2-0.9-6.2-2.9-6.2-6.3c0-2.1,0.8-3.8,2.3-5.1%0Ac1.6-1.3,3.6-1.9,6.2-1.9C41.8,29.1,43.6,29.5,45.5,30.5'%3E%3C/path%3E%3Cpath class='st1' d='M70,32.4c-2.2-2.2-4.8-3.2-8.1-3.2c-3.2,0-5.9,1.1-8.1,3.2c-2.2,2.1-3.2,4.8-3.2,7.9s1.1,5.8,3.2,7.9%0Ac2.2,2.1,4.9,3.2,8.1,3.2c3.2,0,5.9-1.1,8.1-3.2c2.2-2.2,3.3-4.8,3.3-7.9c0-0.5,0-0.9-0.1-1.4h-5.4l0,0h-6.6v4.2h6.1%0Ac-0.3,0.6-0.6,1.1-1.1,1.6c-1.1,1.2-2.6,1.8-4.2,1.8c-1.7,0-3.1-0.6-4.2-1.8C56.6,43.4,56,42,56,40.3s0.6-3.2,1.7-4.4%0Ac1.1-1.2,2.6-1.8,4.2-1.8c1.7,0,3.1,0.6,4.2,1.8c0.3,0.3,0.5,0.6,0.7,0.9h5.9C72.2,35.1,71.3,33.6,70,32.4'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";

const WebsiteMenu = ({ data }) => {
  const { t } = useTranslation();
  if (!data)
    return (
      <React.Fragment>
        <Nav.Link
          className="osg-header-base__menu__item osg-header-base__menu__item--skeleton"
          disabled
        ></Nav.Link>
        <Nav.Link
          className="osg-header-base__menu__item osg-header-base__menu__item--skeleton"
          disabled
        ></Nav.Link>
        <Nav.Link
          className="osg-header-base__menu__item osg-header-base__menu__item--skeleton"
          disabled
        ></Nav.Link>
        <Nav.Link
          className="osg-header-base__menu__item osg-header-base__menu__item--skeleton"
          disabled
        ></Nav.Link>
        <Nav.Link
          className="osg-header-base__menu__item osg-header-base__menu__item--skeleton"
          disabled
        ></Nav.Link>
      </React.Fragment>
    );
  return (
    <React.Fragment>
      {Object.entries(data).map(([title, value], index) => (
        <div className="osg-header-base__menu__item" key={index}>
          <Nav.Link
            href={value.href}
            target={value.target}
            className={value.class}
          >
            {title}
          </Nav.Link>
          {value.children && (
            <NavDropdown
              renderMenuOnMount={true}
              title={<span className="sr-only">{t('Open Dropdown')}</span>}
            >
              <div className="osg-header-base__menu__item__group">
                {Object.entries(value.children).map(
                  ([title2, value2], index2) => {
                    if (value2.type === 'heading') {
                      return (
                        <div
                          className="osg-header-base__menu__item__group__section"
                          key={index2}
                        >
                          <NavDropdown.Header className={value2.class}>
                            {title2}
                          </NavDropdown.Header>
                          {Object.entries(value2.children).map(
                            ([title3, value3], index3) => (
                              <NavDropdown.Item
                                key={index3}
                                href={value3.href}
                                className={value3.class}
                              >
                                {title3}
                              </NavDropdown.Item>
                            )
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <NavDropdown.Item
                          key={index2}
                          href={value2.href}
                          className={value2.class}
                        >
                          {title2}
                        </NavDropdown.Item>
                      );
                    }
                  }
                )}
              </div>
            </NavDropdown>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};

const MobileWebsiteMenu = ({ data }) => {
  if (!data)
    return (
      <React.Fragment>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
        <div className="osg-menu-item nav-item osg-menu-item--skeleton"></div>
      </React.Fragment>
    );
  return (
    <React.Fragment>
      {Object.entries(data).map(([title, value], index) => (
        <div key={index}>
          <Nav.Link
            href={value.href}
            target={value.target}
            className={value.class}
          >
            {title}
          </Nav.Link>
          {value.children && (
            <div className="osg-header-base__mobile-menu__group">
              {Object.entries(value.children).map(
                ([title2, value2], index2) => {
                  if (value2.type === 'heading') {
                    return (
                      <div
                        className="osg-header-base__mobile-menu__header"
                        key={index2}
                      >
                        <div className={value2.class}>{title2}</div>
                        {Object.entries(value2.children).map(
                          ([title3, value3], index3) => (
                            <Nav.Link
                              key={index3}
                              href={value3.href}
                              className={value3.class}
                            >
                              {title3}
                            </Nav.Link>
                          )
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <Nav.Link
                        key={index2}
                        href={value2.href}
                        className={value2.class}
                      >
                        {title2}
                      </Nav.Link>
                    );
                  }
                }
              )}
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};

export const HeaderBase = props => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <div className="osg-header-base">
        <Navbar expand="md" variant="light">
          <Nav className="ms-auto osg-header-base__menu" aria-label={t('Menu')}>
            <a className="navbar-brand" href="https://osg.ca">
              <img src={logo} alt="OSG" loading="lazy" width="56" height="56" />
            </a>
            <Navbar.Collapse id="top-navbar-nav">
              <WebsiteMenu data={props.data} />
            </Navbar.Collapse>
            {props.children}
          </Nav>
          <button
            onClick={handleShow}
            aria-controls="osgSlideout"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className={
              props.mobile === false
                ? 'navbar-toggler d-md-none'
                : 'navbar-toggler'
            }
            id="osgSlideoutToggle"
          >
            <div className="osg-header-base__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  fill="currentColor"
                  d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"
                />
              </svg>
            </div>
            <span className="navbar-toggler__text">{t('Menu')}</span>
          </button>
        </Navbar>
      </div>
      {/* Slideout Nav For Mobile View */}
      <Offcanvas
        className="osg-offcanvas"
        show={show}
        onHide={handleClose}
        aria-label={t('Menu')}
        placement="end"
      >
        <Offcanvas.Header>
          <Offcanvas.Title></Offcanvas.Title>
          <button
            onClick={handleClose}
            aria-label={t('Close')}
            className="navbar-toggler"
          >
            <span aria-hidden="true">
              <div className="osg-header-base__toggle-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                  />
                </svg>
              </div>
            </span>
            <span className="navbar-toggler__text">{t('Close')}</span>
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="osg-header-base__mobile-menu">
            <Nav className="flex-column d-md-none">
              <MobileWebsiteMenu data={props.data} />
            </Nav>
            {props.mobile}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};
