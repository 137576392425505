import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '../MenuItem';

export const TranslateSwitch = ({ className, menu, skeleton, dropdown }) => {
  const { i18n } = useTranslation();

  const classes = classNames({
    'osg-translate-switch': true,
    'osg-translate-switch--menu': menu,
    [className]: className,
  });

  const changeLang = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('fr');
    } else {
      i18n.changeLanguage('en');
    }
  };

  if (menu) {
    return (
      <MenuItem
        className={classes}
        icon="globe"
        onClick={changeLang}
        skeleton={skeleton}
      >
        {i18n.language === 'en' ? 'Offert en Français' : 'Offered in English'}
      </MenuItem>
    );
  }

  if (dropdown) {
    return (
      <NavDropdown.Item className={classes} onClick={changeLang}>
        {i18n.language === 'en' ? 'Offert en Français' : 'Offered in English'}
      </NavDropdown.Item>
    );
  }

  return (
    <div className={classes}>
      <Button
        variant="link"
        size="plain"
        style={{ verticalAlign: 'inherit' }}
        onClick={changeLang}
      >
        {i18n.language === 'en' ? 'Offert en Français' : 'Offered in English'}
      </Button>
    </div>
  );
};

TranslateSwitch.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.bool,
  skeleton: PropTypes.bool,
  dropdown: PropTypes.bool,
};

TranslateSwitch.defaultProps = {
  className: null,
  menu: false,
  skeleton: false,
  dropdown: false,
};
