import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { addSeconds, isBefore } from 'date-fns';

import { Icon } from '../components/Icon';

import { LoginContext } from '../actions/userRoles';

// Gives us the current metadata of a user
export const useClientMetadata = () => {
  const { currentLogin } = useContext(LoginContext);
  return {
    userAgent: window.navigator.userAgent,
    userName: currentLogin?.username,
    location: window.location.href,
  };
};

// Get a new date object the specified time in the future
const GetFutureDate = future_seconds => addSeconds(new Date(), future_seconds);
// Returns a hook to a callback that can only be called once at most every specified interval
export const useTimeGuardedCallback = (minInterval, callback) => {
  const nextUpdate = useRef(GetFutureDate(minInterval));
  const makeCallback = useCallback(
    callback_args => {
      if (isBefore(new Date(), nextUpdate.current)) return;
      nextUpdate.current = GetFutureDate(minInterval);
      callback(callback_args);
    },
    [minInterval, callback]
  );
  return [makeCallback];
};

// Given a callback, list of target dom elements, and events / keys to watch for
//   When componant mounts this hook will bind the callback to all specified events on specified elements
export const useMountedListeners = (
  callback,
  { targets, events, keys },
  dontBind
) => {
  const getBindPoints = typeof targets === 'function' ? targets : () => targets;
  events = events || [];
  keys = keys || [];
  useEffect(() => {
    if (dontBind) return;
    const keyboardHandler = event => keys.includes(event.code) && callback();
    const addListeners = () => {
      getBindPoints().forEach(item => {
        events.forEach(event => item.addEventListener(event, callback, false));
        item.addEventListener('keydown', keyboardHandler, false);
      });
    };
    const removeListeners = () => {
      getBindPoints().forEach(item => {
        events.forEach(event => item.addEventListener(event, callback, false));
        item.removeEventListener('keydown', keyboardHandler, false);
      });
    };
    const listenerTimer = setTimeout(addListeners, 3000);
    return () => {
      clearTimeout(listenerTimer);
      removeListeners();
    };
  }, [getBindPoints, events, keys, callback, dontBind]);
};

// Returns the query params as an object
export const useURLQueryParams = () =>
  Object.fromEntries(new URLSearchParams(useLocation().search));

// Get size.width, size.height of the current window size.
export const useWindowSize = () => {
  const isClient = typeof window === 'object';
  const [windowSize, setWindowSize] = useState([]);
  const [debouncedWindowSize] = useDebounce(windowSize, 300);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]);

  return debouncedWindowSize;
};

function setupWebSocketConnection(url, t) {
  window.WSC = new ReconnectingWebSocket(url);

  window.WSC.addEventListener('message', event => {
    if (event.data?.length !== 40 || event.data === __RELEASE_REV__) return;

    toast.info(
      <div className="osg-version-refresh">
        <Icon icon="sync" variant="duotone" size="fa-lg" className="fa-fw" />
        {t('A new version of this app is available.')}
        <Button
          onClick={() => window.location.reload()}
          variant="link"
          size="plain"
        >
          {t('REFRESH')}
        </Button>
      </div>,
      {
        onClose: () => window.location.reload(),
        hideProgressBar: true,
        closeOnClick: true,
        autoClose: false,
        toastId: 'deployment-notification',
      }
    );
  });
}

function requestLatestDeployedRelease(event) {
  if (event?.data !== 'wakeup' || !__RELEASE_REV__) {
    return;
  }
  setTimeout(() => window.WSC.send(__RELEASE_REV__), 8000);
}

export const useVersionRefresh = worker => {
  const { t } = useTranslation();

  worker.addEventListener('message', requestLatestDeployedRelease);

  useEffect(() => {
    const wsUrl = process.env.WS_URL;

    if (!wsUrl || window.WSC?.readyState > -1) return;

    setupWebSocketConnection(wsUrl, t);
  }, [t]);
};

export const useScript = src => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    // Allow falsy src value if waiting on other data needed for
    // constructing the script URL passed to this hook.
    if (!src) {
      setStatus('idle');
      return;
    }
    // Fetch existing script element by src
    // It may have been added by another intance of this hook
    let script = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      // Add script to document body
      document.body.appendChild(script);
      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = event => {
        script.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error'
        );
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script.getAttribute('data-status'));
    }
    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = event => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    // Add event listeners
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    // Remove event listeners on cleanup
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src]); // Only re-run effect if script src changes

  return status;
};

export const useTextContent = initial => {
  const [textContent, setTextContent] = useState(initial);

  const ref = useCallback(node => {
    if (node !== null) {
      setTextContent(node.textContent);
    }
  }, []);

  ref.current = textContent;
  return ref;
};
