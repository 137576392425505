import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';

import { EmployeesQuery } from '../graphql/EmployeesQuery.gql';
import { InvoicesQuery } from '../graphql/Invoice.gql';

export const MenuContext = React.createContext({});

export const MenuProvider = props => {
  const [employeeCount, setEmployeeCount] = useState(null);
  const [invoiceCount, setInvoiceCount] = useState({});
  const [wordpressMenus, setWordpressMenus] = useState(
    window.wordpressMenus || {}
  );

  const getWordpressMenus = useCallback(async () => {
    try {
      const response = await fetch('https://osg.ca/json/header_footer.php');
      const json = await response.json();
      setWordpressMenus(json);
    } catch (error) {
      console.error(error);

      setWordpressMenus({
        header: {
          'Error Loading Menu': { href: '/', children: null },
        },
        footer: {
          menu: [],
          widgets: [],
          info: ['Error Loading Footer'],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!wordpressMenus || !wordpressMenus.header || !wordpressMenus.footer) {
      getWordpressMenus();
    }
  }, []);

  // Ignore employee count for now to speed up site
  const { loading: employeeLoading, error: employeeError } = useQuery(
    EmployeesQuery,
    {
      skip: true, //!isLoggedIn(),
      // TODO: Remove the train session count once we have more employee managment stuff in place
      onCompleted: data =>
        data &&
        setEmployeeCount(
          data.employees.filter(employee => employee.trainSessionCount > 0)
            .length
        ),
    }
  );

  // Ignore badges for now to speed up site, until we have better purpose
  const { loading: invoicesLoading, error: invoicesError } = useQuery(
    InvoicesQuery,
    {
      skip: true, //!isLoggedIn(),
      onCompleted: data =>
        data &&
        setInvoiceCount({
          total: data.invoices.length,
          withBalance: data.invoices.filter(invoice => invoice.balance > 0)
            .length,
        }),
    }
  );

  const menu = {
    wordpress: wordpressMenus,
    loading: [employeeLoading, invoicesLoading].some(
      currentValue => currentValue === true
    ),
    employee: {
      loading: employeeLoading,
      error: employeeError,
      count: employeeCount,
      setEmployeeCount,
    },
    invoices: {
      loading: invoicesLoading,
      error: invoicesError,
      total: invoiceCount.total,
      withBalance: invoiceCount.withBalance,
      setInvoiceCount,
    },
  };

  return (
    <MenuContext.Provider value={menu}>{props.children}</MenuContext.Provider>
  );
};

export const MenuConsumer = MenuContext.Consumer;

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
