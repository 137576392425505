import * as ActiveStorage from 'activestorage';

const BASE_DOMAIN =
  process.env.NODE_ENV === 'development' ? process.env.API_URL : '';
const DIRECT_UPLOAD_URL = `${BASE_DOMAIN}/rails/active_storage/direct_uploads`;
export const DirectUploader = class {
  constructor(file, { onError, onComplete, onUpdate }) {
    Object.assign(this, { file, onError, onComplete, onUpdate });
    this.directUpload = new ActiveStorage.DirectUpload(
      file,
      DIRECT_UPLOAD_URL,
      this
    );
  }

  // Callback when we receive new information regarding progress
  uploadRequestDidProgress = event =>
    this.onUpdate({ loaded: event.loaded, total: event.total });

  // If we are using XHR we want to bind our progress monitor
  directUploadWillStoreFileWithXHR = request =>
    request.upload.addEventListener('progress', event =>
      this.uploadRequestDidProgress(event)
    );

  // Start the direct upload to the server
  start = () =>
    this.directUpload.create((error, attributes) =>
      error ? this.onError(error) : this.onComplete(attributes)
    );
};
